import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/error-404.png'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_q_layout = _resolveComponent("q-layout")!

  return (_openBlock(), _createBlock(_component_q_layout, {
    view: "lHh Lpr lFf",
    class: "layout"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Header),
      _cache[0] || (_cache[0] = _createElementVNode("div", null, [
        _createElementVNode("div", {
          class: "absolute-center",
          style: {"text-align":"center"},
          id: "content-container"
        }, [
          _createElementVNode("div", { id: "not-found-image-container" }, [
            _createElementVNode("div", { id: "not-found-description" }, " Так сталося, що нічого не сталося "),
            _createElementVNode("div", { id: "not-found-client-message" }, " Повертайся пізніше, а ми тут почаклуємо "),
            _createElementVNode("img", {
              src: _imports_0,
              id: "not-found-page-image"
            })
          ])
        ])
      ], -1)),
      _createVNode(_component_Footer)
    ]),
    _: 1
  }))
}